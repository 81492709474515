import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SideArrow } from '../assets/icons/sideArrow.svg';
interface Props {
  direction?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '100vw',
    textAlign: 'center'
  },
  sideArrowDiv: {
    display: 'flex',
    justifyContent: 'start'
  },
  navigation: {
    cursor: 'pointer',
    flexWrap: 'wrap',
    [theme.breakpoints.up('xs')]: {
      zIndex: 1,
      position: 'absolute'
    },
    [theme.breakpoints.down('xs')]: {
      zIndex: 0,
      position: 'relative'
    }
  }
}));

const SharedBackButtonComponent: FC<Props> = (props: Props) => {
  const { direction } = { ...props };
  const rotation = direction === 'up' ? 'rotate(90deg)' : '';
  const history = useHistory();
  const classes = useStyles();
  const handleNavigation = () => {
    history.goBack();
  };

  return (
    // Display the back button
    <div className={classes.sideArrowDiv}>
      <SideArrow
        className={classes.navigation}
        onClick={handleNavigation}
        style={{ transform: rotation }}
      />
    </div>
  );
};

export default SharedBackButtonComponent;
