import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ShotType, ShotTypes, SizeType, SizeTypes } from '../interfaces/cup';
import { ShotPrice, SizePrice } from '../interfaces/price';
import { DrinkInterface } from '../interfaces/product';
import { getShotPrices, getSizePrices } from '../services/dataService';
import { applyPromotion, convertToEuro, getMobileWidthThreshold } from '../utils/helper';

const useStyles = makeStyles((theme) => ({
  // root page layout
  rootElement: {
    display: 'inline-block',
    justifyItems: 'center',
    width: '100vw',
    minHeight: 'auto',
    marginLeft: '-5px',
    textAlign: 'left',
    paddingBottom: '2vh',
    paddingTop: '2vh',
    zIndex: 1
  },
  header: {
    fontWeight: 400
  },
  // the default layout of choosing the drink size
  buttonDrinkSize: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: 'none',
    borderRadius: '4px',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    width: '100px',
    height: '100px',
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('md')]: {
      width: '100px',
      height: '100px'
    },

    fontWeight: 'normal',
    margin: '8px'
  },
  // the layout of the selected size
  buttonDrinkSizeSelected: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid',
    borderBlockColor: '#634543',
    borderRadius: '4px',
    textAlign: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('md')]: {
      width: '100px',
      height: '100px'
    },
    fontWeight: 'bold',
    margin: '8px'
  },
  // the image that represents the cup sizes
  imageHeightAndWidth: {
    justifyItems: 'center',
    width: '50px',
    height: '50px'
  },
  // the default layout of choosing the drink coffee shot options
  buttonDrinkShots: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: 'none',
    borderRadius: '4px',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    backgroundColor: 'white',
    width: '100px',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('md')]: {
      width: '100px',
      height: '100px'
    },
    fontWeight: 'normal',
    margin: '8px'
  },
  // the layout of the selected coffee shot options
  buttonDrinkShotsSelected: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid',
    borderBlockColor: '#634543',
    borderRadius: '4px',
    textAlign: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.up('md')]: {
      width: '100px',
      height: '100px'
    },
    fontWeight: 'bold',
    margin: '8px'
  },
  // the container for each button in the grid
  choiceContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    display: 'block',
    fontWeight: 'bold',
    textAlign: 'center',
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      marginLeft: '48px',

      width: '60px'
    }
  }
}));

interface Props {
  setPrice(arg0: number): void;
  product: DrinkInterface;
}

const CupShot: FC<Props> = (props) => {
  const { setPrice, product } = { ...props };
  const classes = useStyles();

  const location = useParams<{ location: string }>();

  // These two configurations determine the state
  const [selectedSize, setSelectedSize] = React.useState<SizeType>(SizeTypes.Medium);
  const [selectedShots, setSelectedShots] = React.useState<ShotType>(ShotTypes.Single);

  // get the data of the product and apply promotions
  const sizePrices = getSizePrices(product).map((price: SizePrice) => {
    price.price = applyPromotion(price.price, location.location);
    return price;
  });
  const shotPrices = getShotPrices().map((shot: ShotPrice) => {
    shot.price = applyPromotion(shot.price, location.location);
    return shot;
  });

  // Set initial pricing
  setPrice(
    (sizePrices.find((elem) => elem.size === selectedSize)?.price ?? 0.0) +
      (shotPrices.find((elem) => elem.shot === selectedShots)?.price ?? 0.0)
  );
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile: boolean = width <= getMobileWidthThreshold();

  return (
    <div className={classes.rootElement}>
      {/* TODO add xs, sm, md */}
      {isMobile ? (
        <Box alignItems="center" justifyItems="center">
          <Grid item className={classes.title}>
            CUP SIZES
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {/* Initial spacing for layout */}
            {/* Define all possible size options */}
            {sizePrices.map(function (sizePrice) {
              return (
                <Grid item xs="auto">
                  <div
                    className={
                      selectedSize === sizePrice.size
                        ? classes.buttonDrinkSizeSelected
                        : classes.buttonDrinkSize
                    }
                    onClick={() => {
                      setSelectedSize(sizePrice.size);
                      setPrice(
                        (shotPrices.find((elem) => elem.shot === selectedShots)?.price ?? 0.0) +
                          sizePrice.price
                      );
                    }}
                  >
                    <div className={classes.choiceContainer}>
                      <div>
                        <img
                          src={sizePrice.imgSrc}
                          alt="${sizePrice.displayText} size"
                          width="25px"
                          height="25px"
                        />
                      </div>
                      <div>
                        <div>{sizePrice.displayText}</div>
                        <div> {convertToEuro(sizePrice.price)} </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Grid item className={classes.title}>
            COFFEE SHOTS
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            {/* Define all possible coffee shot options */}
            {shotPrices.map(function (shotPrice) {
              return (
                <Grid item xs="auto">
                  <div
                    className={
                      selectedShots === shotPrice.shot
                        ? classes.buttonDrinkShotsSelected
                        : classes.buttonDrinkShots
                    }
                    onClick={() => {
                      setSelectedShots(shotPrice.shot);
                      setPrice(
                        (sizePrices.find((elem) => elem.size === selectedSize)?.price ?? 0.0) +
                          shotPrice.price
                      );
                    }}
                  >
                    <div className={classes.choiceContainer}>
                      <div>{shotPrice.displayText}</div>
                      <div>
                        {' '}
                        {shotPrice.price != 0 ? `+ ${convertToEuro(shotPrice.price)}` : '-'}{' '}
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Box alignItems="center" justifyItems="center">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item className={classes.title}>
              CUP SIZES
            </Grid>
            {/* Define all possible size options */}
            {sizePrices.map(function (sizePrice) {
              return (
                <Grid item xs="auto">
                  <div
                    className={
                      selectedSize === sizePrice.size
                        ? classes.buttonDrinkSizeSelected
                        : classes.buttonDrinkSize
                    }
                    onClick={() => {
                      setSelectedSize(sizePrice.size);
                      setPrice(
                        (shotPrices.find((elem) => elem.shot === selectedShots)?.price ?? 0.0) +
                          sizePrice.price
                      );
                    }}
                  >
                    <div className={classes.choiceContainer}>
                      <div>
                        <img
                          src={sizePrice.imgSrc}
                          alt="${sizePrice.displayText} size"
                          width="25px"
                          height="25px"
                        />
                      </div>
                      <div>
                        <div>{sizePrice.displayText}</div>
                        <div> {convertToEuro(sizePrice.price)} </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
            <Grid item className={classes.title}>
              COFFEE SHOTS
            </Grid>
            {/* Define all possible coffee shot options */}
            {shotPrices.map(function (shotPrice) {
              return (
                <Grid item xs="auto">
                  <div
                    className={
                      selectedShots === shotPrice.shot
                        ? classes.buttonDrinkShotsSelected
                        : classes.buttonDrinkShots
                    }
                    onClick={() => {
                      setSelectedShots(shotPrice.shot);
                      setPrice(
                        (sizePrices.find((elem) => elem.size === selectedSize)?.price ?? 0.0) +
                          shotPrice.price
                      );
                    }}
                  >
                    <div className={classes.choiceContainer}>
                      <div>{shotPrice.displayText}</div>
                      <div>
                        {' '}
                        {shotPrice.price != 0 ? `+ ${convertToEuro(shotPrice.price)}` : '-'}{' '}
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default CupShot;
