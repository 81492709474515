import { DrinkInterface, PriceInterface, ProductInterface } from '../interfaces/product';
import { DrinksPerCategoryInterface } from '../interfaces/product';
import { RecommendationInterface } from '../interfaces/recommendation';
import bannerFerragniCoffee from '../assets/backgrounds/chiara-ferragni-bg.png';
import bannerTropicalMocktail from '../assets/backgrounds/tropical-bg.png';
import shortLongDrinks from '../assets/drinks/short-long-cups.png';
import milkDrinks from '../assets/drinks/milk-recipes.png';
import coldDrinks from '../assets/drinks/cold-drinks.png';
import specialDrinks from '../assets/drinks/specials.png';
import tropicalMocktail from '../assets/drinks/tropical-mocktail-ice.png';
import chiaraFerragniCoffee from '../assets/drinks/chiara-coffee.png';
import additionalAsset from '../assets/chiara-signature.png';
import { ProductCategoryInterface } from '../interfaces/productCategory';
import { SizePrice, ShotPrice } from '../interfaces/price';
import { SizeTypes, ShotTypes } from '../interfaces/cup';
import longCup from '../assets/icons/long cup icon.svg';
import mediumCup from '../assets/icons/medium cup icon.svg';
import smallCup from '../assets/icons/short cup icon.svg';
import { AddInInterface, BeansInterface } from '../interfaces/productDetails';
import iceCubesIcon from '../assets/icons/ice-cubes-icon.svg';
import syrupIcon from '../assets/icons/syrup-icon.svg';
import chocolatePowderIcon from '../assets/icons/chocolate-powder.svg';
import coconutFlakesIcon from '../assets/icons/coconut-flakes.svg';
import nespressoProducts from '../data/product.json';

export const getProducts = (): ProductInterface[] => {
  const products: ProductInterface[] = [
    { name: 'Short & Long Cups', description: '' },
    { name: 'Milk Recipes', description: '' },
    { name: 'Cold Drinks', description: '' },
    { name: 'Specials', description: 'Chocolate, Tea, Milk Shakes' }
  ];
  return products;
};

export const getRecommendations = (): RecommendationInterface[] => {
  const recommendations: RecommendationInterface[] = [
    {
      name: 'Tropical  <br> Coconut Flavour <br> Over Ice Mocktail',
      bannerSrc: bannerTropicalMocktail,
      image: tropicalMocktail,
      new: true
    },
    {
      name: "Chiara Ferragni's favorite  <br> coffee selection:",
      bannerSrc: bannerFerragniCoffee,
      image: chiaraFerragniCoffee,
      new: false,
      additionalAsset: additionalAsset
    }
  ];
  return recommendations;
};

export const getCategories = (): ProductCategoryInterface[] => {
  const categories: ProductCategoryInterface[] = [
    { id: 1, name: 'Short & Long Cups', description: '', bannerSrc: shortLongDrinks },
    { id: 2, name: 'Milk Recipes', description: '', bannerSrc: milkDrinks },
    { id: 3, name: 'Cold Drinks', description: '', bannerSrc: coldDrinks },
    {
      id: 4,
      name: 'Specials',
      description: 'Chocolate, Tea, Milk Shakes',
      bannerSrc: specialDrinks
    }
  ];
  return categories;
};

export const getProductsPerCategory = (categoryId: string): DrinkInterface[] => {
  const products: DrinksPerCategoryInterface[] = nespressoProducts.products;
  const resultIndex: number = products.findIndex((eachProduct: DrinksPerCategoryInterface) => {
    return eachProduct.categoryId === categoryId;
  });

  return products[resultIndex]['productsPerCategory'];
};

export const getProductDetailById = (productId: string): string => {
  if (productId) {
    return 'result';
  } else {
    return '';
  }
};

// Define all possible size configurations
export const getSizePrices = (product: DrinkInterface): SizePrice[] => {
  const sizePrices: SizePrice[] = [
    {
      displayText: 'SMALL',
      size: SizeTypes.Small,
      price:
        product.drinkDetail.price.find((price: PriceInterface) => price.cupSize === SizeTypes.Small)
          ?.cost || 0.0,
      imgSrc: smallCup
    },
    {
      displayText: 'MEDIUM',
      size: SizeTypes.Medium,
      price:
        product.drinkDetail.price.find(
          (price: PriceInterface) => price.cupSize === SizeTypes.Medium
        )?.cost || 0.0,
      imgSrc: mediumCup
    },
    {
      displayText: 'LARGE',
      size: SizeTypes.Large,
      price:
        product.drinkDetail.price.find((price: PriceInterface) => price.cupSize === SizeTypes.Large)
          ?.cost || 0.0,
      imgSrc: longCup
    }
  ];
  return sizePrices;
};

// Define all possible coffee shots configurations
export const getShotPrices = (): ShotPrice[] => {
  const shotPrices: ShotPrice[] = [
    {
      displayText: 'SINGLE',
      shot: ShotTypes.Single,
      price: 0
    },
    {
      displayText: 'DOUBLE',
      shot: ShotTypes.Double,
      price: 0.4
    },
    {
      displayText: 'TRIPLE',
      shot: ShotTypes.Triple,
      price: 0.8
    }
  ];
  return shotPrices;
};

export const getBeans = (): BeansInterface[] => {
  const beans: BeansInterface[] = [
    { name: 'SINGLE ORIGIN', selected: false },
    { name: 'Colombia', selected: false },
    { name: 'Hulia Garzon', selected: false },
    { name: 'Round & smooth\nIdeal for milk recipes', selected: false }
  ];
  return beans;
};

export const getAddIns = (): AddInInterface[] => {
  const addIns: AddInInterface[] = [
    {
      name: 'Sugar - half a teaspoon',
      iconSrc: iceCubesIcon,
      price: 0.0,
      displayPrice: 0.0,
      selected: false,
      latteOnly: false
    },
    {
      name: 'Vanilla syrup',
      iconSrc: syrupIcon,
      price: 0.25,
      displayPrice: 0.25,
      selected: false,
      latteOnly: true
    },
    {
      name: 'Chocolate powder',
      iconSrc: chocolatePowderIcon,
      price: 0.2,
      displayPrice: 0.2,
      selected: false,
      latteOnly: false
    },
    {
      name: 'Coconut flakes',
      iconSrc: coconutFlakesIcon,
      price: 0.25,
      displayPrice: 0.25,
      selected: false,
      latteOnly: true
    }
  ];
  return addIns;
};
