import React, { FC, useEffect, useState } from 'react';
import { getAddIns } from '../services/dataService';
import { makeStyles, FormControlLabel, Checkbox, Grid, IconButton } from '@material-ui/core';
import { convertToEuro, getApplicablePromoProductIds, isOneFreeAddIn } from '../utils/helper';
import { AddInInterface } from '../interfaces/productDetails';
import { useParams } from 'react-router';

interface Props {
  editAddIns: (value: AddInInterface[]) => void;
  productId: string;
  isLatte: boolean;
}

const useStyles = makeStyles((theme) => ({
  dialogCross: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '& img': {
      width: '20px',
      margin: '15px',
      cursor: 'pointer'
    }
  },
  dialogTitle: {
    padding: 0,
    width: '70%',
    alignSelf: 'center',
    marginBottom: '25px',
    '& h1': {
      fontSize: '32px',
      textAlign: 'center',
      lineHeight: '1',
      margin: 0
    }
  },
  dialogContent: {
    margin: '10px 10px 25px 10px'
  },
  selectionContainer: {
    width: '100%',
    borderBottom: '1px solid #a48150',
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectionTitle: {
    color: '#a48150',
    margin: '0px 0px 10px 0px',
    textTransform: 'uppercase'
  },
  addIn: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0px',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px'
    }
  },
  sugarAddIn: {
    marginLeft: '11px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0px',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '6px',
      maxWidth: '80px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '170px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'max-content'
    }
  },
  checkbox: {
    alignSelf: 'flex-start',
    color: '#a48150',
    '&$checked': {
      color: '#a48150'
    }
  },
  icon: {
    width: '15px',
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      marginRight: '5px',
      width: '15px'
    }
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: '50px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    width: '18px',
    height: '18px',
    color: 'white',
    backgroundColor: 'black',
    padding: '0',
    margin: '2px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#a48150'
    }
  },
  addInItem: {
    textAlign: 'left'
  },
  checked: {},
  promotionCaption: {
    margin: '8px'
  }
}));

export const addInConfig: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { editAddIns, productId, isLatte } = { ...props };
  const [counter, setCounter] = useState(0);
  const location = useParams<{ location: string }>();

  const getAllAddIns = () => {
    const addIns: AddInInterface[] = [];
    if (isLatte) {
      getAddIns().map((addIn) => addIns.push(addIn));
    } else {
      getAddIns().map((addIn) => (!addIn.latteOnly ? addIns.push(addIn) : null));
    }
    return addIns;
  };
  // initialize all addIns
  const allAddIns: AddInInterface[] = getAllAddIns();
  const [addIns, setAddIns] = useState<AddInInterface[]>(allAddIns);

  const handleChange = (name: string) => {
    const applicableProductIds = getApplicablePromoProductIds(location.location);
    // check if free add in promo should be applied
    if (isOneFreeAddIn(location.location) && applicableProductIds.includes(productId)) {
      // get most expensive addin
      const mostExpensiveAddIn: AddInInterface = addIns.reduce(function (prev, current) {
        return current.price > prev.price &&
          ((current.selected && current.name != name) ||
            (!current.selected && current.name === name))
          ? current
          : prev;
      });
      // update the new addins and show a different price if this item is the most expensive
      setAddIns(
        addIns.map((addIn) =>
          addIn.name === mostExpensiveAddIn.name
            ? {
                ...addIn,
                displayPrice: 0,
                selected: addIn.name === name ? !addIn.selected : addIn.selected
              }
            : {
                ...addIn,
                displayPrice: addIn.price,
                selected: addIn.name === name ? !addIn.selected : addIn.selected
              }
        )
      );
    } else {
      setAddIns(
        // invert selection flag of clicked checkbox item
        addIns.map((addIn) =>
          addIn.name === name ? { ...addIn, selected: !addIn.selected } : { ...addIn }
        )
      );
    }
  };

  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if (counter != 0) {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    editAddIns(addIns);
  }, [addIns]);

  return (
    <div>
      {isOneFreeAddIn(location.location) ? (
        <strong className={classes.promotionCaption}>Promotion: Get one for FREE</strong>
      ) : (
        <div></div>
      )}
      {addIns.map((addIn: AddInInterface, index) => (
        <Grid container alignItems="center" direction="row">
          <Grid className={classes.addInItem} item xs={10}>
            {addIn.name.toLowerCase().includes('sugar') ? (
              <Grid container alignItems="center" direction="row">
                <div className={classes.buttonContainer}>
                  <IconButton className={classes.button} size="small" onClick={handleIncrement}>
                    +
                  </IconButton>
                  <IconButton className={classes.button} size="small" onClick={handleDecrement}>
                    -
                  </IconButton>
                </div>
                <p>{counter}</p>
                <div key={index} className={classes.sugarAddIn}>
                  <img src={addIn.iconSrc} className={classes.icon} />
                  <p>{addIn.name}</p>
                </div>
              </Grid>
            ) : (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    classes={{ root: classes.checkbox, checked: classes.checked }}
                    checked={addIn.selected}
                    onChange={(event) => handleChange(event.target.name)}
                    name={addIn.name}
                    value={addIn.name}
                  />
                }
                label={
                  <div key={index} className={classes.addIn}>
                    <img src={addIn.iconSrc} className={classes.icon} />
                    <p>{addIn.name}</p>
                  </div>
                }
              />
            )}
          </Grid>
          <Grid item xs={2}>
            {convertToEuro(addIn.displayPrice)}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default addInConfig;
