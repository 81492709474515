import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProductCategoryInterface } from '../interfaces/productCategory';
interface Props {
  products: ProductCategoryInterface[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    paddingBottom: '2vh',
    paddingLeft: '10vw',
    paddingRight: '10vw'
  },
  product: {
    display: 'block',
    backgroundColor: 'white',
    textAlign: 'center',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingTop: '2vh',
    paddingBottom: '2vh',
    '&:hover': {
      cursor: 'pointer',
      border: '3px solid #AE8E62'
    },
    height: '8vh',
    [theme.breakpoints.down('xs')]: {
      height: '8vh !important'
    },
    [theme.breakpoints.down('md')]: {
      height: '10vh'
    },
    [theme.breakpoints.down('sm')]: {
      height: '18vh'
    },
    boxShadow: '0px 0px 10px #0000003D',
    border: '3px solid white',
    borderRadius: '4px'
  },
  productImage: {
    width: 120,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 60
    }
  },
  productName: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5em'
    }
  },
  productDescription: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left !important'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    display: 'grid'
  }
}));

export const ProductCategories: FC<Props> = (props) => {
  const { products } = { ...props };
  const classes = useStyles();
  const history = useHistory();
  const location = useParams<{ location: string }>();

  function goToDetails(category: ProductCategoryInterface) {
    let path = '';
    if (location.location) {
      path = location.location + `/products/${category.id}`;
    } else {
      path = `/products/${category.id}`;
    }
    history.push({
      pathname: path,
      state: [{ category }]
    });
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {products &&
          products.map((product: ProductCategoryInterface, index) => (
            <Grid key={index} item xs={12} sm={6} md={6}>
              <div
                className={classes.product}
                onClick={() => {
                  goToDetails(product);
                }}
              >
                <Grid container spacing={3}>
                  <Grid key={index} item xs={5} sm={12} md={6}>
                    <img src={product.bannerSrc} alt="product" className={classes.productImage} />
                  </Grid>
                  <Grid
                    key={index}
                    item
                    xs={7}
                    sm={12}
                    md={6}
                    className={classes.productDescription}
                  >
                    <h3 className={classes.productName}> {product.name} </h3>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
