import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import { RecommendationInterface } from '../interfaces/recommendation';

interface Props {
  recommendations: RecommendationInterface[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingBottom: '2vh',
    paddingLeft: '10vw',
    paddingRight: '10vw',
    marginTop: '0vh'
  },
  banner: {
    width: '100%',
    height: '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  line: {
    marginBottom: ' 5px'
  },
  recommendationTitle: {
    fontWeight: 400,
    fontSize: '27px',
    marginBottom: '5vh'
  },
  recommendationImage: {
    width: '5vw',
    height: '10vh',
    [theme.breakpoints.down('xs')]: {
      height: '10vh',
      width: '16vw !important'
    },
    [theme.breakpoints.down('md')]: {
      height: '10vh',
      width: '10vw'
    }
  },
  recommendation2Image: {
    width: '4vw',
    height: '6vh',
    marginTop: '1vh',
    [theme.breakpoints.down('xs')]: {
      height: '7vh',
      width: '16vw !important'
    },
    [theme.breakpoints.down('md')]: {
      height: '7vh',
      width: '10vw'
    }
  },
  recommendationDescription: {
    textAlign: 'left',
    display: 'grid'
  },
  recommendation: {
    display: 'block',
    backgroundColor: 'white',
    textAlign: 'left',
    paddingLeft: '5vw',
    height: '13vh',
    [theme.breakpoints.down('xs')]: {
      height: '15vh !important'
    },
    [theme.breakpoints.down('sm')]: {
      height: '15vh !important'
    },
    paddingTop: '3vh',
    boxShadow: '0px 0px 10px #0000003D',
    borderRadius: '4px'
  },
  new: {
    marginBottom: '10px'
  },
  signature: {
    width: 'auto',
    maxWidth: 'auto',
    height: '46px'
  }
}));

export const Recommendations: FC<Props> = (props) => {
  const { recommendations } = { ...props };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2 className={classes.recommendationTitle}>RECOMMENDED TODAY </h2>
      <Grid container spacing={3}>
        {recommendations &&
          recommendations.map((recommendation: RecommendationInterface, index) => (
            <Grid key={index} item xs={12} sm={6} md={6}>
              <div
                className={classes.recommendation}
                style={{
                  background: `url(${recommendation.bannerSrc})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <Grid container spacing={3}>
                  <Grid key={index} item xs={4} md={4}>
                    <img
                      src={recommendation.image}
                      alt="recommendation"
                      className={
                        index === 0 ? classes.recommendationImage : classes.recommendation2Image
                      }
                    />
                  </Grid>
                  <Grid
                    key={index}
                    item
                    xs={6}
                    md={5}
                    className={classes.recommendationDescription}
                  >
                    {recommendation.new && (
                      <span className={classes.new}>
                        <strong>NEW</strong>
                      </span>
                    )}
                    {recommendation.name.split('<br>').map((line: string) => (
                      <span className={classes.line}>{line}</span>
                    ))}
                    {recommendation.additionalAsset && (
                      <img
                        src={recommendation.additionalAsset}
                        alt="recommendation"
                        className={classes.signature}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
