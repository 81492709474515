import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const useStyles = makeStyles(() => ({
  dialogTitleCancelIcon: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialogHeaderText: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 400,
    letterSpacing: '0.2em',
    size: '10px !important',
    marginTop: '0',
    marginBottom: '0'
  },
  iconTextDisplay: {
    display: 'flex',
    alignItems: 'center',
    padding: '50px',
    gap: '8px'
  },

  MuiDialogActionsRoot: {
    justifyContent: 'space-around !important',
    marginBottom: '2vh'
  },
  dialogActionBtn: {
    borderRadius: '40px',
    height: '3vh',
    width: '12vw',
    letterSpacing: '0.2em',
    fontSize: '10px',
    color: 'white'
  }
}));

interface Props {
  dialogOpen: boolean;
  handleClose: () => void;
  handlePaymentProceed: () => void;
}

const PaymentDialog: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const paymentDialog = 'Please follow the instructions on the payment terminal.';
  return (
    <Dialog
      open={props.dialogOpen}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { height: '300px', width: '820px' }
      }}
    >
      <DialogTitle>
        <div className={classes.dialogTitleCancelIcon}>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <h3 className={classes.dialogHeaderText}> {'PAYMENT'}</h3>
      </DialogTitle>
      <DialogContent
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
      >
        <DialogContentText style={{ color: 'black' }} id="alert-dialog-description">
          {paymentDialog}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.MuiDialogActionsRoot}>
        <Button
          className={classes.dialogActionBtn}
          style={{
            background: '#624644',
            marginRight: '10px'
          }}
          variant="contained"
          onClick={props.handlePaymentProceed}
        >
          Proceed
        </Button>
        <Button
          className={classes.dialogActionBtn}
          style={{ background: 'black', marginLeft: '10px' }}
          variant="contained"
          onClick={props.handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withMobileDialog()(PaymentDialog);
