import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Background from '../../assets/backgrounds/milkRecipesBg.png';
import CupShot from '../../components/cupShot';
import { Price } from '../../components/price';
import { ProductDetails } from '../../components/productDetails';
import { DrinkDetailInterface, DrinkInterface } from '../../interfaces/product';
import { AddInInterface } from '../../interfaces/productDetails';
import { getProductsPerCategory } from '../../services/dataService';
import SharedBackButtonComponent from '../../shared/SharedBackButtonComponent';
import SharedHeaderComponent from '../../shared/SharedHeaderComponent';
import { applyAddInPromotion, getMobileWidthThreshold } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${Background})`,
    backgroundSize: '100% 60%',
    backgroundRepeat: 'no-repeat',
    paddingTop: '10px',
    height: 'auto',
    width: 'auto',

    //  minHeight: '100vh',
    backgroundColor: '#fcf7f1',
    [theme.breakpoints.up('sm')]: {
      height: '80vh'
    },

    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  mainGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentGrid: {
    display: 'inline-grid',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignContent: 'space-evenly',
      height: '75vh'
    }
  },
  content: {
    display: 'inline'
  },
  backNavigation: {
    zIndex: 2
  }
}));

const ProductDetailPage: FC = () => {
  const classes = useStyles();
  const [pageTitle, setPageTitle] = useState<string>('');
  const [coffeeConfigPrice, setCoffeeConfigPrice] = useState<number>(0.0);
  const [coffeeAddInPrice, setCoffeeAddInPrice] = useState<number>(0.0);
  const { categoryId, drinkId } = useParams<{ categoryId: string; drinkId: string }>();
  const location = useParams<{ location: string }>();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= getMobileWidthThreshold();

  // Define detault values if product cannot be retrieved
  const defaultDrinkDetailInterface: DrinkDetailInterface = {
    beanType: 'None',
    currency: 'None',
    price: []
  };
  const defaultProduct: DrinkInterface = {
    drinkDetail: defaultDrinkDetailInterface,
    drinkName: 'None',
    id: 'None',
    img: 'None'
  };

  const product =
    getProductsPerCategory(categoryId).find((product: DrinkInterface) => product.id === drinkId) ||
    defaultProduct;

  const [coffeeAddIns, setCoffeeAddIns] = useState<AddInInterface[]>([]);

  useEffect(() => {
    let totalAddInPrice = 0;
    coffeeAddIns.map((addIn) => (totalAddInPrice += addIn.price));
    totalAddInPrice = applyAddInPromotion(totalAddInPrice, location.location, coffeeAddIns);
    setCoffeeAddInPrice(totalAddInPrice);
  }, [coffeeAddIns]);

  useEffect(() => {
    const title = product?.drinkName;
    if (title != 'None') {
      setPageTitle(title);
    } else {
      setPageTitle('Product Overview');
    }
  }, []);

  return (
    <div>
      <div className={classes.container}>
        {isMobile ? <SharedBackButtonComponent direction="up" /> : null}
        <Grid container className={classes.mainGrid} direction="row">
          <Grid item xs={1} className={classes.backNavigation}>
            {isMobile ? null : <SharedBackButtonComponent />}
          </Grid>
          <Grid item xs={10}>
            <div className={classes.content}>
              <Grid container direction="column" className={classes.contentGrid}>
                <SharedHeaderComponent pageTitle={pageTitle} />
                <ProductDetails setCoffeeAddIns={setCoffeeAddIns} details={product} />
                <CupShot setPrice={setCoffeeConfigPrice} product={product} />
              </Grid>
            </div>
          </Grid>

          {/* Just for trailing layout */}
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <div>
        <Price price={coffeeConfigPrice} addInPrice={coffeeAddInPrice} />
      </div>
    </div>
  );
};

export default ProductDetailPage;
