import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { FC, useEffect, useState } from 'react';
import beanIcon from '../assets/icons/bean-icon.png';
import { DrinkInterface } from '../interfaces/product';
import { AddInInterface } from '../interfaces/productDetails';
import { getAddIns, getBeans } from '../services/dataService';
import { getImage, getMobileWidthThreshold } from '../utils/helper';
import AddInConfig from './addInConfig';
import BeansConfig from './beansConfig';

interface Props {
  setCoffeeAddIns: (value: AddInInterface[]) => void;
  details: DrinkInterface;
}

interface Bean {
  name: string;
  selected: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // paddingBottom: '2vh',
    width: '100vw',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '90vw'
    },
    [theme.breakpoints.up('md')]: {
      width: '90vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: '70vw'
    },
    margin: '0 auto'
  },
  titleContainer: {
    width: '100%',
    borderBottom: '1px dashed black',
    marginBottom: '15px'
  },
  titleContainerBean: {
    width: '100%',
    borderBottom: '1px dashed black',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    margin: '0px 0px 10px 0px',
    textTransform: 'uppercase'
  },
  content: {
    margin: '12px 0px',
    '& p': {
      margin: '5px'
    }
  },
  bean: {
    fontSize: '16px',
    whiteSpace: 'pre-line'
  },
  selectedBean: {
    fontSize: '25px',
    fontWeight: 'bold'
  },
  button: {
    color: 'black',
    textTransform: 'uppercase',
    backgroundColor: 'white',
    padding: '10px 20px',
    borderRadius: '25px',
    border: 'none',
    boxShadow: '1px 2px 3px #888888',
    cursor: 'pointer'
  },
  illustrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    alignItems: 'center'
  },
  illustration: {
    height: '50%',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  },
  decoration: {
    width: '75%',
    [theme.breakpoints.down('xs')]: {
      transform: 'translateX(-50%)',
      width: '50%',
      left: '60%'
    },
    height: 'auto',
    zIndex: 1,
    position: 'absolute',
    alignSelf: 'end',
    bottom: 0
  },
  icon: {
    width: '15px',
    marginRight: '15px'
  },
  beanIcon: {
    width: '20px',
    margin: '0px 5px 10px 0px'
  },
  addIn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0px',
    textAlign: 'left'
  }
}));

export const ProductDetails: FC<Props> = (props) => {
  const classes = useStyles();
  const { setCoffeeAddIns, details } = { ...props };
  const [addIns, setAddIns] = useState<AddInInterface[]>(getAddIns);
  const [beanConfigOpen, setBeanConfigOpen] = React.useState(false);
  const allBeans: Bean[] = getBeans();
  allBeans.forEach((bean) => {
    if (bean.name === details.drinkDetail.beanType) {
      bean.selected = true;
    } else {
      bean.selected = false;
    }
  });
  const [beans, setBeans] = useState<Bean[]>(allBeans);

  const beanHandleClose = () => {
    setBeanConfigOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const modifyBean = () => {
    setBeanConfigOpen(!beanConfigOpen);
    setBeans(beans);
  };

  useEffect(() => {
    const selectedAddIns: AddInInterface[] = [];
    addIns.forEach((addIn) => (addIn.selected ? selectedAddIns.push(addIn) : null));
    setCoffeeAddIns(selectedAddIns);
  }, [addIns]);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile: boolean = width <= getMobileWidthThreshold();
  return (
    <div className={classes.root}>
      <BeansConfig
        beans={beans}
        defaultBean={details.drinkDetail.beanType}
        isOpen={beanConfigOpen}
        onClose={beanHandleClose}
      />
      {!isMobile ? (
        <Grid container direction="row" spacing={2} className={classes.root}>
          <Grid item md={4} sm={4} xs={10}>
            <div className={classes.titleContainerBean}>
              <img src={beanIcon} className={classes.beanIcon} />
              <h3 className={classes.title}>Bean</h3>
            </div>
            <div className={classes.content}>
              {beans.map((bean: Bean, index) => (
                <p key={index} className={bean.selected ? classes.selectedBean : classes.bean}>
                  {bean.name}
                </p>
              ))}
            </div>
            {/* The modifyBean() implementation is wrong, removing the button onClick() action for now */}
            {/* <button onClick={modifyBean} className={classes.button}> */}
            <button className={classes.button}>Modify</button>
          </Grid>
          <Grid item md={4} sm={4} xs={10}>
            <div className={classes.illustrationContainer}>
              <img
                src={getImage(details.img)}
                className={classes.illustration}
                alt={details.drinkName}
              />
              {/*{details?.decorationImg ? (
              <img
                className={classes.decoration}
                src={getImage(details?.decorationImg)}
                alt={'Illustration decoration'}
              />
            ) : null} */}
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={10}>
            <div className={classes.titleContainer}>
              <h3 className={classes.title}>Add-In</h3>
            </div>
            <div className={classes.content}>
              <AddInConfig
                editAddIns={setAddIns}
                productId={details.id}
                isLatte={details.drinkName.toLowerCase().includes('latte') ? true : false}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" spacing={2} className={classes.root}>
          <Grid item md={4} sm={4} xs={10}>
            <div className={classes.illustrationContainer}>
              <img
                src={getImage(details.img)}
                className={classes.illustration}
                alt={details.drinkName}
              />
              {/*{details?.decorationImg ? (
              <img
                className={classes.decoration}
                src={getImage(details?.decorationImg)}
                alt={'Illustration decoration'}
              />
            ) : null} */}
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={10}>
            <div className={classes.titleContainerBean}>
              <img src={beanIcon} className={classes.beanIcon} />
              <h3 className={classes.title}>Bean</h3>
            </div>
            <div className={classes.content}>
              {beans.map((bean: Bean, index) => (
                <p key={index} className={bean.selected ? classes.selectedBean : classes.bean}>
                  {bean.name}
                </p>
              ))}
            </div>
            {/* The modifyBean() implementation is wrong, removing the button onClick() action for now */}
            {/* <button onClick={modifyBean} className={classes.button}> */}
            <button className={classes.button}>Modify</button>
          </Grid>
          <Grid item md={4} sm={4} xs={10}>
            <div className={classes.titleContainer}>
              <h3 className={classes.title}>Add-In</h3>
            </div>
            <div className={classes.content}>
              <AddInConfig
                editAddIns={setAddIns}
                productId={details.id}
                isLatte={details.drinkName.toLowerCase().includes('latte') ? true : false}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
