import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import nespressoLogo from '../assets/logo.png';
const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    marginTop: '87px',
    width: '100vw',
    textAlign: 'center'
  },
  header: {
    fontWeight: 500,
    letterSpacing: '0.2em',
    size: '16px'
  }
}));

export const NespressoHeader: FC<{ pageTitle: string }> = ({ pageTitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={nespressoLogo} alt="logo" />
      {pageTitle && <h2 className={classes.header}>{pageTitle} </h2>}
    </div>
  );
};
