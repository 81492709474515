import { Box, Grid, makeStyles, Switch } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import nutritionFacts from '../assets/icons/nutrition_facts.png';
import { convertToEuro } from '../utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    width: '100%',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      bottom: '0',
      height: '12vh',
      width: '100%'
    }
  },
  orderBtn: {
    width: '286px',
    height: '57px',
    borderRadius: '56px',
    backgroundColor: '#5C4846',
    display: 'flex',
    margin: '4px',
    color: 'white',
    cursor: 'pointer'
  },
  btnElement: {
    width: '100%',
    display: 'flex',

    color: 'white',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  useOwnUp: {
    margin: '8px'
  },
  cupToggle: {
    margin: '8px',
    colorPrimary: '#5C4846'
  },
  iconHelper: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle'
  },
  icon: {
    width: '45px',
    marginRight: '10px',
    display: 'flex',
    float: 'left'
  },
  spanText: {
    fontSize: '20px'
  }
}));

export const Price: FC<{ price: number; addInPrice: number }> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useParams<{ location: string }>();
  const [usesOwnMug, setUsesOwnMug] = useState(false);

  const goToPayment = () => {
    let path = '';
    if (location.location) {
      path = `/${location.location}/payment`;
    } else {
      path = '/payment';
    }
    history.push(path);
  };

  function toggleUsesOwnMug() {
    setUsesOwnMug(!usesOwnMug);
  }

  return (
    <div className={classes.root}>
      <Box alignItems="center" justifyItems="center">
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          {/* TODO add xs, sm, md */}
          <Grid item xs="auto">
            <div>
              <div className={classes.iconHelper}>
                <img src={nutritionFacts} alt="connect" className={classes.icon} />
              </div>
              <span className={classes.spanText}>Nutrition Facts</span>
            </div>
          </Grid>
          <Grid item className={classes.orderBtn} xs="auto" onClick={goToPayment}>
            <div className={classes.btnElement}>
              <div className={classes.spanText}>
                {convertToEuro(
                  usesOwnMug ? props.price + props.addInPrice - 0.2 : props.price + props.addInPrice
                )}
              </div>
              <div className={classes.spanText}>
                <strong>ORDER</strong>
              </div>
            </div>
            {/*} <div className={classes.btnElement}>
              {convertToEuro(
                usesOwnMug ? props.price + props.addInPrice - 0.2 : props.price + props.addInPrice
              )}
            </div>
            <div className={classes.btnElement}>
              <strong>ORDER</strong>
              </div>*/}
          </Grid>
          <Grid item xs="auto">
            <div className={classes.useOwnUp}>
              <Switch className={classes.cupToggle} onClick={toggleUsesOwnMug} />
              <span className={classes.spanText}>Use your own mug and save 0,20 € </span>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
