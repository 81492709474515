import './App.css';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './pages/landing/LandingPage';
import ProductsPage from './pages/products/ProductsPage';
import ProductDetailPage from './pages/productDetail/ProductDetailPage';
import PaymentPage from './pages/payment/PaymentPage';
import PaymentResultPage from './pages/paymentResult/PaymentResultPage';

const App: FC = () => {
  return (
    <Switch>
      <Route path="/:location?/products/:id" component={ProductsPage} />
      <Route
        path="/:location?/product-detail/:categoryId/:drinkId/"
        component={ProductDetailPage}
      />
      <Route path="/:location?/payment" component={PaymentPage} />
      <Route path="/:location?/payment-result" component={PaymentResultPage} />
      <Route path="/:location?" component={LandingPage} />
    </Switch>
  );
};

export default App;
