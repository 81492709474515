import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect } from 'react';
import CheckInIcon from '../assets/check-in-icon.png';
import ConnectIcon from '../assets/connect-icon.png';
import LanguageIcon from '../assets/language-icon.png';
import QrSample from '../assets/qrcode.png';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '90vw',
    textAlign: 'left',
    background: '#F4E8DC',
    paddingLeft: '10vw',
    paddingTop: '2vh',
    paddingBottom: '2vh'
  },
  header: {
    fontWeight: 400
  },
  button: {
    overflow: 'hidden'
  },
  icon: {
    background: '#fbf7f5',
    marginRight: '15px',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      width: '40px'
    }
  },
  qrCode: {
    width: '200px',
    background: '#fbf7f5',
    height: '200px',
    marginTop: '20px'
  },
  modal: {
    position: 'absolute',
    background: '#fbf7f5',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    padding: '50px'
  },
  buttons: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em !important'
    }
  },
  connectIcon: {
    marginLeft: '10vw',
    width: '50px',
    height: '50px',
    float: 'right'
  },
  languageIcon: {
    float: 'right',
    width: '50px',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      width: '40px'
    }
  },
  language: {
    display: 'inline',
    marginLeft: '5.3vw'
  }
}));

export const ConnectFooter: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const checkIn = () => {
    setChecked(true);
    handleClose();
    localStorage.setItem('checkedIn', 'true');
  };
  const checkOut = () => {
    setChecked(false);
    handleClose();
    localStorage.setItem('checkedIn', 'false');
  };
  useEffect(() => {
    if (localStorage.getItem('checkedIn') === 'true') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={4} md={3} className={classes.buttons}>
          <div
            className={classes.button}
            aria-label="connect icon"
            // onClick={ }
          >
            <img src={ConnectIcon} alt="connect" className={classes.icon} />
            <span>
              {' '}
              <strong>Connect </strong> <br />
              your Intervallo app{' '}
            </span>
          </div>
        </Grid>
        <Grid item xs={5} md={6} className={classes.buttons}>
          {
            //Check if message failed
            checked ? (
              <div className={classes.button} aria-label="connect icon">
                <img src={CheckInIcon} alt="connect" className={classes.icon} onClick={checkOut} />
                <span>
                  <strong>John Doe</strong>
                </span>
                <div>
                  <span onClick={checkOut}>Check out</span>
                </div>
              </div>
            ) : (
              <div className={classes.button} aria-label="connect icon">
                <img
                  src={CheckInIcon}
                  alt="connect"
                  className={classes.icon}
                  onClick={handleOpen}
                />
                <span>
                  <strong>Check-in </strong> <br />
                  as Coffee Club Member{' '}
                </span>
              </div>
            )
          }
        </Grid>
        <Grid item xs={1} md={1} className={classes.language}>
          <div
            aria-label="connect icon"
            // onClick={ }
          >
            <img src={LanguageIcon} alt="connect" className={classes.languageIcon} />
          </div>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <strong>Check-in </strong> <br />
            as Coffee Club Member{' '}
          </Typography>
          <Typography id="modal-modal-description">
            <img src={QrSample} alt="connect" className={classes.qrCode} />
          </Typography>
          <Button variant="outlined" onClick={checkIn}>
            Check in
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
