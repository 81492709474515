import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import beanIcon from '../assets/bean-icon-2.png';
import CloseIcon from '../assets/close-icon.png';
import { BeansInterface } from '../interfaces/productDetails';
interface Props {
  beans: BeansInterface[];
  defaultBean: string;
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogCross: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '& img': {
      width: '20px',
      margin: '15px',
      cursor: 'pointer'
    }
  },
  dialogTitle: {
    padding: 0,
    width: '70%',
    alignSelf: 'center',
    marginBottom: '25px',
    '& h1': {
      fontSize: '32px',
      textAlign: 'center',
      lineHeight: '1',
      margin: 0
    }
  },
  dialogContent: {
    margin: '10px 10px 25px 10px'
  },
  selectionContainer: {
    width: '100%',
    borderBottom: '1px solid #a48150',
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  beanIcon: {
    width: '50px',
    margin: '0px 0px 10px 0px'
  },
  selectionTitle: {
    color: '#a48150',
    margin: '0px 0px 10px 0px',
    textTransform: 'uppercase'
  },
  radioGroup: {
    margin: 0,
    width: '100%',
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px'
      },
      fontSize: '18px',
      color: 'black'
    }
  },
  formControl: {
    margin: 0,
    width: '100%'
  },
  radio: {
    alignSelf: 'flex-start',
    color: '#a48150',
    '&$checked': {
      color: '#a48150'
    }
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: '50px'
  },
  button: {
    width: '50%',
    maxWidth: '300px',
    color: 'white',
    backgroundColor: 'black',
    padding: '10px 20px',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#a48150'
    }
  },
  checked: {}
}));

export const beansConfig: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { beans, defaultBean, isOpen, onClose } = { ...props };
  const [selectedBean, setSelectedBean] = useState<string>(defaultBean);

  const modifyBeans = () => {
    beans.forEach((bean) => {
      if (bean.name === selectedBean) {
        bean.selected = true;
      } else {
        bean.selected = false;
      }
    });
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={'md'} disableBackdropClick open={isOpen} onClose={onClose}>
        <div className={classes.dialogCross}>
          <img src={CloseIcon} onClick={onClose} />
        </div>
        <DialogTitle className={classes.dialogTitle}>
          <h1>PIMP YOUR COFFEE</h1>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.selectionContainer}>
            <img src={beanIcon} className={classes.beanIcon} />
            <h2 className={classes.selectionTitle}>Bean</h2>
          </div>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              row
              aria-label="Bean"
              className={classes.radioGroup}
              value={selectedBean}
              onChange={(event) => setSelectedBean(event.target.value)}
            >
              <Grid container justifyContent="space-between" spacing={2}>
                {beans.map((bean, index) => (
                  <Grid item xs={6} sm={6} md={3}>
                    <FormControlLabel
                      key={index}
                      value={bean.name}
                      control={
                        <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                      }
                      label={bean.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            className={classes.button}
            onClick={() => {
              modifyBeans();
              onClose();
            }}
          >
            Modify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default beansConfig;
