import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import Background from '../../assets/backgrounds/bg-desktop.png';
import { ConnectFooter } from '../../components/connectFooter';
import { NespressoHeader } from '../../components/nespressoHeader';
import { ProductCategories } from '../../components/productCategories';
import { Recommendations } from '../../components/recommendations';
import { getCategories, getRecommendations } from '../../services/dataService';

const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: `url(${Background})`,
    backgroundSize: '100% 60%',
    backgroundRepeat: 'no-repeat',
    width: 'auto',
    height: '100vh',
    minHeight: '100%',
    maxHeight: 'auto'
  },
  content: {
    height: 'auto',
    minHeight: '70%'
  },
  footer: {
    position: 'relative',
    bottom: 0
  }
}));

const LandingPage: FC = () => {
  const classes = useStyles();
  const recommendations = getRecommendations();
  const products = getCategories();
  const pageTitle = 'IN THE MOOD FOR';
  return (
    <main className={classes.container}>
      <NespressoHeader pageTitle={pageTitle} />
      <div className={classes.content}>
        <ProductCategories products={products} />
        <Recommendations recommendations={recommendations} />
      </div>
      <div className={classes.footer}>
        <ConnectFooter></ConnectFooter>
      </div>
    </main>
  );
};

export default LandingPage;
