import blank from '../assets/decorations/blank.png';
import vanillaFlower from '../assets/decorations/vanilla-flower.png';
import cappucino from '../assets/drinks/cappuccino.png';
import cortado from '../assets/drinks/cortado.png';
import espresso from '../assets/drinks/espresso.png';
import flatWhite from '../assets/drinks/flatWhite.png';
import latteMacchiato from '../assets/drinks/latteMacchiato.png';
import vanillaLatte from '../assets/drinks/vanillaLatteSmallAgain.png';
import { AddInInterface } from '../interfaces/productDetails';

function getImage(fileName: string): string {
  const fileFirstName = fileName.split('.')[0];
  if (fileFirstName === 'cappuccino') {
    return cappucino;
  } else if (fileFirstName === 'cortado') {
    return cortado;
  } else if (fileFirstName === 'latteMacchiato') {
    return latteMacchiato;
  } else if (fileFirstName === 'flatWhite') {
    return flatWhite;
  } else if (fileFirstName === 'vanillaLatte') {
    return vanillaLatte;
  } else if (fileFirstName === 'vanillaFlower') {
    return vanillaFlower;
  } else if (fileFirstName === 'espresso') {
    return espresso;
  } else if (fileFirstName === 'none') {
    return blank;
  } else {
    return cappucino;
  }
}

export { getImage };

export function applyPromotion(price: number, location: string): number {
  let discount = 0.0;
  if (location) {
    if (localStorage.getItem('checkedIn') === 'true' && location === 'houston') {
      // apply 50% off on all items if user is checkIn and form houston
      discount = discount + 0.5;
    }
  }
  return price - price * discount;
}

export function applyAddInPromotion(
  price: number,
  location: string,
  addIns: AddInInterface[]
): number {
  if (isOneFreeAddIn(location) && addIns.length > 0) {
    // apply promotion of most expensive add-in for free
    price -= Math.max(
      ...addIns.map(function (addIn) {
        return addIn.price;
      })
    );
  }
  return price;
}

export function isOneFreeAddIn(location: string): boolean {
  // promotion only applicable for certain locations
  return location === 'houston';
}

export function getApplicablePromoProductIds(location: string): string[] {
  let productIds: string[] = [];
  if (location === 'houston') {
    productIds = ['3', '5'];
  }
  return productIds;
}

export function convertToEuro(n: number): string {
  if (n === 0.0) return 'FREE';
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(n);
}

export function getMobileWidthThreshold(): number {
  // this is the threshold when the user is on a mobile device
  return 600;
}
