import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductList from '../../components/productList';
import { DrinkInterface } from '../../interfaces/product';
import { ProductCategoryInterface } from '../../interfaces/productCategory';
import { getCategories, getProductsPerCategory } from '../../services/dataService';
import SharedBackButtonComponent from '../../shared/SharedBackButtonComponent';
import SharedHeaderComponent from '../../shared/SharedHeaderComponent';
import { getMobileWidthThreshold } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#f7eee7',
    alignContent: 'center',
    justifyContent: 'space-around',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  },
  mainGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentGrid: {
    display: 'inline-grid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'block',
    height: '100%',
    minHeight: '100vh'
  }
}));

const ProductsPage: FC = () => {
  const classes = useStyles();
  const [products, setProducts] = useState<DrinkInterface[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const { id } = useParams<{ id: string }>();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const categories: ProductCategoryInterface[] = getCategories();

    const title = categories.find(
      (category: ProductCategoryInterface) => category?.id.toString() === id
    )?.['name'];
    if (title) {
      setPageTitle(title);
    } else {
      setPageTitle('Available Drinks');
    }
    const productList: DrinkInterface[] = getProductsPerCategory(id);
    setProducts(productList);
  }, []);

  const isMobile: boolean = width <= getMobileWidthThreshold();

  return (
    <div className={classes.container}>
      {isMobile ? <SharedBackButtonComponent direction="up" /> : null}
      <Grid container className={classes.mainGrid} direction="row">
        <Grid item xs={1}>
          {isMobile ? null : <SharedBackButtonComponent />}
        </Grid>
        <Grid item xs={10}>
          <div className={classes.content}>
            <Grid className={classes.container} container direction="column">
              <SharedHeaderComponent pageTitle={pageTitle} />
              <ProductList categoryId={id} products={products} />
            </Grid>
          </div>
        </Grid>

        {/* Just for trailing layout */}
        {/* <Grid item xs={1}></Grid> */}
      </Grid>
    </div>
  );
};

export default ProductsPage;
