import { Grid, makeStyles } from '@material-ui/core';

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Background from '../../assets/backgrounds/bg-desktop.png';
import Card from '../../assets/card icon_desktop.svg';
import Cash from '../../assets/cash icon_desktop.svg';
import NessAndYou from '../../assets/Nespresso&you_desktop.svg';
import SharedBackButtonComponent from '../../shared/SharedBackButtonComponent';
import { getMobileWidthThreshold } from '../../utils/helper';
import PaymentDialog from '../../components/PaymentDialog';
import nespressoLogo from '../../assets/logo.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  header: {
    display: 'inline-block',
    width: '100vw',
    textAlign: 'center',
    fontWeight: 500,
    letterSpacing: '0.2em',
    size: '16px',
    textTransform: 'uppercase'
  },
  cardImgWidth: {
    width: '36x',
    height: '36px'
  },
  MuiDialogRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'red'
  },
  dialogTitleCancelIcon: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialogHeaderText: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 400,
    letterSpacing: '0.2em',
    size: '10px !important',
    marginTop: '0',
    marginBottom: '0'
  },
  container: {
    display: 'flex',
    minHeight: '100vh'
  },
  iconTextDisplay: {
    display: 'flex',
    alignItems: 'center',
    padding: '50px',
    gap: '8px'
  },
  nesspressoImgWidth: {
    width: '200px',
    height: '48px'
  },
  buttonCard: {
    width: '286px',
    height: '174px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 10px #0000003D',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  MuiDialogActionsRoot: {
    justifyContent: 'space-around !important'
  },
  dialogActionBtn: {
    borderRadius: '40px',
    height: '4vh',
    width: '12vw',
    letterSpacing: '0.2em',
    fontSize: '10px',
    color: 'white'
  },
  backBtnColGrid: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    paddingBottom: '536px'
  },
  contentGrid: {
    display: 'inline-grid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'inline'
  },
  logoStyle: {
    display: 'flex',
    marginTop: '87px'
  }
}));

const PaymentPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useParams<{ location: string }>();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= getMobileWidthThreshold();

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handlePaymentProceed = () => {
    let path = '';
    if (location.location) {
      path = `/${location.location}/payment-result`;
    } else {
      path = '/payment-result';
    }
    history.push(path);
  };

  return (
    <div className={classes.root}>
      {isMobile ? <SharedBackButtonComponent direction="up" /> : null}
      {!isMobile && (
        <div className={classes.logoStyle}>
          <img src={nespressoLogo} alt="logo" />
        </div>
      )}

      <h2 className={classes.header}>CHOOSE YOUR PAYMENT METHODS</h2>
      <Grid container direction="row">
        <Grid item xs={3} md={1} lg={1} className={classes.backBtnColGrid}>
          {isMobile ? null : <SharedBackButtonComponent />}
        </Grid>
        <Grid item xs={8} md={11} lg={11} className={classes.container}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={8} md={4} lg={3}>
              <div className={classes.buttonCard}>
                <div className={classes.iconTextDisplay}>
                  <div>
                    <img src={Cash} className={classes.cardImgWidth} />
                  </div>
                  <div>
                    <h3>CASH </h3>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={4} lg={3} onClick={handleClickOpen}>
              <div className={classes.buttonCard}>
                <div className={classes.iconTextDisplay}>
                  <div>
                    <img src={Card} className={classes.cardImgWidth} />
                  </div>
                  <div>
                    <h3>CREDIT CARD </h3>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={4} lg={3}>
              <div className={classes.buttonCard}>
                <div className={classes.iconTextDisplay}>
                  <div>
                    <img src={NessAndYou} className={classes.nesspressoImgWidth} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={1} lg={5}></Grid>
          </Grid>
          <PaymentDialog
            dialogOpen={dialogOpen}
            handleClose={handleClose}
            handlePaymentProceed={handlePaymentProceed}
          />
        </Grid>
        {/* Just for trailing layout */}
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
};

export default PaymentPage;
