import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Background from '../../assets/backgrounds/bg-desktop.png';
import nespressoLogo from '../../assets/logo.png';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundSize: '100% 60%',
    backgroundRepeat: 'no-repeat',
    width: 'auto',
    height: 'auto',
    minHeight: '100vh',
    overflowY: 'scroll'
  },
  header: {
    display: 'inline-block',
    marginTop: '87px',
    width: '100vw',
    textAlign: 'center'
  },
  product: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    width: '60vw',
    height: '50vh',
    fontFamily: 'Lato Light',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#8c8c8c',
    backgroundColor: '#FFFDF7',
    paddingLeft: '15px',
    paddingRight: '15px',
    boxShadow: '0px 0px 10px #0000003D'
  }
}));

const PaymentResultPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useParams<{ location: string }>();
  const displayText = 'Thank you for your purchase. Please grab your drink.';

  useEffect(() => {
    localStorage.setItem('checkedIn', 'false');
    setTimeout(() => {
      let path = '';
      if (location.location) {
        path = `/${location.location}`;
      } else {
        path = '/';
      }
      history.push(path);
    }, 10000);
  }, []);

  return (
    <main className={classes.container}>
      <div className={classes.header}>
        <img src={nespressoLogo} alt="logo" />
      </div>
      <div className={classes.product}>{displayText}</div>
    </main>
  );
};

export default PaymentResultPage;
