import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
interface Props {
  pageTitle: string;
  payment?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center'
  },
  navigation: {
    cursor: 'pointer'
  },
  header: {
    fontWeight: 500,
    letterSpacing: '0.2em',
    size: '16px',
    textTransform: 'uppercase',
    paddingTop: '50px'
  }
}));

const SharedHeaderComponent: FC<Props> = (props: Props) => {
  const { pageTitle } = { ...props };
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.header}>{pageTitle}</h2>
    </div>
  );
};

export default SharedHeaderComponent;
