import { Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import plusIconMock from '../assets/icons/plusIconMock.png';
import { DrinkInterface } from '../interfaces/product';
import { applyPromotion, convertToEuro, getImage } from '../utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    paddingBottom: '2vh',
    paddingLeft: '5vw',
    paddingRight: '5vw',
    paddingTop: '16vh'
  },
  productImage: {
    width: 200,
    height: 150
  },

  product: {
    display: 'block',

    backgroundColor: 'white',
    textAlign: 'center',
    padding: '24px',
    // paddingBottom: '2px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '12px',
      paddingBottom: '2px'
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#FFFDF7'
    },
    boxShadow: '0px 0px 10px #0000003D'
  },
  illustrationContainer: {
    display: 'inline-block',
    flexDirection: 'column',
    position: 'relative',
    width: '100%'
  },
  illustration: {
    height: '81px'
  },
  plusIconMock: {
    width: 50,
    height: 50
  },
  flexAddMoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  productAddMore: {
    display: 'block',

    backgroundColor: 'white',
    textAlign: 'center',
    padding: '94px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#FFFDF7'
    },
    boxShadow: '0px 0px 10px #0000003D'
  },
  decoration: {
    width: 78,
    height: 'auto',
    zIndex: 1,
    alignSelf: 'end',
    position: 'absolute',
    bottom: 0,
    left: 'center'
  },
  plusImage: {
    width: '50px'
  },
  textBold: {
    fontWeight: 'bold'
  }
}));

const ProductList: FC<{ categoryId: string; products: DrinkInterface[] }> = ({
  categoryId,
  products
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useParams<{ location: string }>();
  const handleProductDetail = (product: DrinkInterface) => {
    let path = '';
    if (location.location) {
      path = '/' + location.location + `/product-detail/${categoryId}/${product.id}`;
    } else {
      path = `/product-detail/${categoryId}/${product.id}`;
    }
    history.push(path);
  };

  const getDefaultPrice = (eachProduct: DrinkInterface) => {
    let result = 0.0;
    const drinkPriceDefault = eachProduct.drinkDetail.price.find(
      (eachPrice) => eachPrice.cupSize === 'medium'
    );
    if (drinkPriceDefault) {
      result = drinkPriceDefault.cost;
    }
    result = applyPromotion(result, location.location);
    return convertToEuro(result);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        {products.length > 0 &&
          products.map((eachProduct: DrinkInterface, index: number) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                md={4}
                onClick={() => handleProductDetail(eachProduct)}
              >
                <div className={classes.product}>
                  <div className={classes.illustrationContainer}>
                    <img
                      src={getImage(eachProduct.img)}
                      className={classes.illustration}
                      alt="drinks"
                    />
                    {/* <img
                      className={classes.decoration}
                      src={getImage(eachProduct.decorationImg || 'none')}
                      alt={'Illustration decoration'}
                  /> */}
                  </div>

                  <p className={classes.textBold}>
                    {' '}
                    {eachProduct.drinkName}
                    <br />
                    {getDefaultPrice(eachProduct)}
                  </p>
                </div>
              </Grid>
            );
          })}
        {/* if the header is not milk recipes then no display of this add more*/}
        {products.length !== 0 ? (
          <Grid item xs={12} md={4}>
            <div className={classes.product}>
              <div className={classes.illustrationContainer}>
                <img src={plusIconMock} alt="plus" className={classes.illustration} />
              </div>

              <p className={classes.textBold}>
                More <br />
                Options{' '}
              </p>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default ProductList;
