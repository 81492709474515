export const enum SizeTypes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}
export type SizeType = SizeTypes.Small | SizeTypes.Medium | SizeTypes.Large;

export const enum ShotTypes {
  Single = 'single',
  Double = 'double',
  Triple = 'triple'
}
export type ShotType = ShotTypes.Single | ShotTypes.Double | ShotTypes.Triple;
